<template>
    <div v-if='visible'>
        <v-data-table :headers="headers"
                      dense
                      :items="itemsSelected"
                      disable-pagination
                      hide-default-footer
                      class="elevation-2 mt-2">

            <template v-slot:item.envia="{ item }">
                <v-simple-checkbox v-model="item.envia"></v-simple-checkbox>
            </template>

            <template v-slot:item.link="{ item }">
                <v-simple-checkbox v-model="item.link"></v-simple-checkbox>
            </template>

            <template v-slot:item.icono="{ item }">
                <v-icon>
                    {{ icono(item.tramiteId) }}
                </v-icon>
            </template>

            <template v-slot:item.tramite="{ item }">
                {{ftramite(item.tramiteId)}}
            </template>

            <template v-slot:item.destino="{ item }">
                {{item.neDestino.nombre}}
            </template>

            <template v-slot:item.direccionSoporte="{ item }">
                {{direccionSoporte(item)}}
            </template>

            <template v-slot:item.asunto="{ item }">
                {{item.asunto}}
            </template>

            <template v-slot:item.mensaje="{ item }">
                {{item.mensaje}}
            </template>


        </v-data-table>
    </div>
</template>

<style>
    .rowItem {
        background-color: red;
    }
</style>

<script>
    export default {
        name: 'neFullToSendGrid',

        props: [
            'value',
            'tramites',
            'emails'
        ],

        data() {
            return {
                headers: [
                    { text: 'Envía', value: 'envia', sortable: false },
                    { text: 'Link', value: 'link', sortable: false },
                    { text: '', value: 'icono', sortable: false },
                    { text: 'Trámite', value: 'tramite', sortable: false },
                    { text: 'Destino', value: 'destino', sortable: false },
                    { text: 'Dirección', value: 'direccionSoporte', sortable: false },
                    { text: 'Asunto', value: 'asunto', sortable: false },
                    { text: 'Mensaje', value: 'mensaje', sortable: false },
                ]
            };
        },
        computed: {
            visible() {
                return this.itemsSelected.length > 0;
            },

            itemsSelected() {
                if (this.value == undefined)
                    return [];
                return this.value;

                //return this.value.filter(item => {
                //    // Many more calculations
                //    if (item.envia !== false) {
                //        return true
                //    } else {
                //        return false
                //    }
                //})
            },

        },

        methods: {
            icono(tramiteId) {
                if (this.tramites !== undefined) {
                    var r = this.tramites.find(function (itm) {
                        if (tramiteId === itm.id) {
                            return itm;
                        }
                    });
                    if (r == undefined) {
                        return '==== Tramite no definido ====';
                    }
                    //this.tramite = r;
                    return r.icono;
                }
                return '=====';
            },

            ftramite(tramiteId) {
                //if (this.tramiteAll.loading) return '';
                if (this.tramites !== undefined) {
                    var r = this.tramites.find(function (itm) {
                        if (tramiteId === itm.id) {
                            return itm;
                        }
                    });
                    if (r == undefined) {
                        return '==== tramite no definido ====';
                    }
                    return r.nombre;
                }
                return '=====';
            },

            direccionSoporte(itm) {
                if (itm.direccionSoporte != "")
                    return itm.direccionSoporte;

                    let m = this.emails.find(x => x.id == itm.neDestinoId);
                if (m !== null) {
                    return m.email;
                }
                else { return ""; }
            },
        },
        components: {
        }
    };
</script>

<style>
    .td {
        background-color: red;
    }
</style>
