<template>
    <div>
        <v-toolbar flat dense color="yellow">
            <v-toolbar-title style="display:flex; align-items:center;">
                <h2> {{ formTitle }}</h2>
                <v-btn class="ml-4"
                       dark
                       small
                       rounded
                       color="error">
                    <!--<galleryLoadImage :domainGuid="guid"></galleryLoadImage>-->
                </v-btn>
            </v-toolbar-title>
        </v-toolbar>

        <v-row>
            <v-col class="d-flex child-flex" cols="2" v-for="(entry, inx) in items" :key="inx">
                <div v-if="hasImage(entry)" @click="handleClick(entry)">
                    <img :src="getThumbnailSrc(imageName(entry), entry.thumbnail)" :alt="entry.nombre" style="width: 80px;" />
                    <p>{{ imageName(entry) }}</p>

                </div>
                <!--<galleryImage :guid="guid" :imageName="item"></galleryImage>-->
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import galleryImage from '@/components/Image/GalleryImage';
    import galleryLoadImage from '@/components/Image/GalleryLoadImage';

    export default {
        name: 'gallery',

        props: [
            'value',
            'items',
            'title'
        ],

        data() {
            return {
            };
        },

        computed: {
            formTitle() {
                return this.title;
            }
        },

        methods: {
            getThumbnailSrc(filename, base64Data) {
                // Inferir el tipo de imagen basándose en la extensión del nombre del archivo.
                let fileType = '';
                if (filename.endsWith('.png')) {
                    fileType = 'image/png';
                } else if (filename.endsWith('.jpg') || filename.endsWith('.jpeg')) {
                    fileType = 'image/jpeg';
                } else {
                    // Default a PNG si no se reconoce la extensión
                    fileType = 'image/png';
                }
                return `data:${fileType};base64,${base64Data}`;
            },

            handleClick(entry) {
                // Aquí puedes realizar alguna acción con el objeto entry
                this.$emit('image-selected', entry.metadata.name);
                console.log('Elemento clickeado:', entry);
            },
            hasImage(entry) {
                return entry !== null && entry.metadata !== null;
            },

            imageName(entry) {
                if (entry == null || entry.metadata == null)
                    return "";
                return entry.metadata.name;
            }
        },
        components: {
            galleryImage,
            galleryLoadImage
        }
    };
</script>
