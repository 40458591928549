<template>
    <v-data-table :headers="_headers"
                  dense
                  :loading="progress"
                  :items="all.items"
                  sort-by="fInicio"
                  sort-desc
                  :search="search"
                  :items-per-page="25"
                  class="elevation-1"
                  @click:row="selectRow">
        <template v-slot:top>
            <v-toolbar flat dense color="yellow">
                <v-toolbar-title>Casos</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>

                <v-checkbox v-if="!usuarioActual"
                            class="pt-3 mr-4"
                            v-model="casosFinalizados"
                            @change="ChgFinalizados"
                            label="Casos finalizados">
                </v-checkbox>

                <v-checkbox class="pt-3 mr-4"
                            v-model="usuarioActual"
                            @change="ChgUsuarioActual"
                            label="Solo usuario actual">
                </v-checkbox>

                <v-spacer></v-spacer>

                <v-text-field v-model="search"
                              append-icon="mdi-magnify"
                              label="Buscar"
                              single-line
                              hide-details></v-text-field>
                <v-spacer></v-spacer>

                <v-dialog v-model="propDialog"
                          hide-overlay
                          fullscreen
                          transition="dialog-bottom-transition"
                          scrollable
                          persistent>
                    <template v-slot:activator="{ }"
                              v-if="getGrant('0103')">
                        <v-btn class="mx-2"
                               fab
                               dark
                               x-small
                               color="warning"
                               @click="selectRow(null)">
                            <v-icon dark>mdi-plus</v-icon>
                        </v-btn>
                    </template>
                    <casoDialogo></casoDialogo>
                </v-dialog>

                <v-dialog v-model="borraDialog" max-width="300px">
                    <v-card>
                        <v-card-title class="pa-0">
                            <v-toolbar flat dense color="yellow">
                                <v-toolbar-title>
                                    Desea Borrar el caso?
                                </v-toolbar-title>
                            </v-toolbar>
                        </v-card-title>
                        <v-card-text class="pa-10">
                            <h2>Dominio: {{ item.nombre }}</h2>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1"
                                   text
                                   @click="deleteObject(item.id)">Borra</v-btn>
                            <v-btn color="blue darken-1"
                                   text
                                   @click="closeBorraDialog">Cancela</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-toolbar>
        </template>

        <template v-slot:item.action="{ item }">
            <v-icon v-if="getGrant('0102')"
                    ma-0
                    small
                    @click.stop="borraItem(item)">
                mdi-delete
            </v-icon>
        </template>

        <template v-slot:item.alerta="{ item }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-icon v-if="getGrant('0103') && item.alerta"
                            pa-0
                            :color="alertaColor(item)"
                            v-bind="attrs"
                            v-on="on">
                        {{alertaIcono(item)}}
                    </v-icon>
                </template>
                <span>{{tooltip(item) + ' ' +item.dAlerta}}</span>
            </v-tooltip>
        </template>

        <template v-slot:item.desarmadero.nombre="{ item }">
            <span :style="diasrudac(item)">{{dsrmdr(item)}}</span>
        </template>

        <template v-slot:item.dropboxLink="{ item }">
            <v-icon v-if="item.dropboxLink"
                    small
                    @click.stop="dropboxClick(item)">
                mdi-dropbox
            </v-icon>
        </template>

        <template v-slot:item.estado.nombre="{ item }">
            <v-chip x-small
                    :text-color="item.estado.textColor"
                    :color="item.estado.color"
                    dark><strong>{{ item.estado.nombre }}</strong></v-chip>
        </template>

        <template v-slot:item.fInicio="{ item }">
            {{ fechaFrmt(item.fInicio) }}
        </template>

        <template v-slot:item.fFin="{ item }">
            {{ fechaFrmt(item.fFin) }}
        </template>

        <template v-slot:item.responsable="{ item }">
            <v-chip x-small
                    text-color="white"
                    color="green"
                    dark><strong>{{ item.responsable }}</strong></v-chip>
        </template>

        <template v-slot:item.seguimiento="{ item }">
            <span v-for="(trm,inx) in item.tramite"
                  :key="inx"
                  class="group ma-1">
                <v-icon small>
                    {{seguimiento(trm)}}
                </v-icon>
            </span>
        </template>

        <template v-slot:item.tipoBaja="{ item }">
            <v-icon pa-0>
                {{item.tipoBaja}}
            </v-icon>
        </template>

        <template v-slot:no-data>
            <v-btn color="yellow" @click="initialize">Reset</v-btn>
        </template>

    </v-data-table>
    <!--<alertaGrid>

    </alertaGrid>-->
</template>

<script>
    //: custom - filter="filterText"

    import { mapState, mapActions } from 'vuex';

    import casoDialogo from '@/components/Caso/CasoDialogo';

    export default {
        name: 'casoCrud',
        data() {
            return {
                search: '',
                lineas: 20,
                headers: [
                    { text: '', value: 'alerta', show: true },
                    { text: 'Rsp', value: 'responsable', show: true },
                    { text: 'Estado', value: 'estado.nombre', show: true },
                    { text: 'TB', value: 'tipoBaja', show: true },
                    { text: 'Seguimiento', value: 'seguimiento', show: false },
                    { text: 'F.Inicio', value: 'fInicio', show: true },
                    { text: 'F.Finaliz.', value: 'fFin', show: true },
                    { text: 'Compañia', value: 'cliente', show: true },
                    { text: 'Siniestro', value: 'siniestro', show: true },
                    { text: 'Dominio', value: 'nombre', show: true },
                    //{ text: 'Marca', value: 'marca', show: true },
                    //{ text: 'Modelo', value: 'modelo', show: true },
                    { text: 'Marca y Modelo', value: 'marcaModelo', show: true },
                    { text: 'Tramitador', value: 'tramitador', show: true },
                    { text: 'Desarmadero', value: 'desarmadero.nombre', show: true },
                    //{ text: 'Dbx', value: 'dropboxLink', show: true },
                    { text: '-', value: 'action', sortable: false, show: true }
                ]
            };
        },
        computed: {
            ...mapState(['ESTADOS']),
            ...mapState('caso', [
                'all',
                'dialog',
                'item',
                'borraDialog',
                'progress',
                'casosFinalizados',
                'usuarioActual'
            ]),
            ...mapState('titular', ['defaultTitular']),
            ...mapState('tramite', { tramiteAll: 'items' }),
            ...mapState('account', ['permisos', 'loggedUser']),

            _headers() {
                return this.headers.filter(x => x.show || this.getGrant('0600'))
            },

            propDialog: {
                get() {
                    return this.dialog;
                },
                set(value) {
                    this.setDialog(value);
                }
            },

        },

        async created() {
            var self = this;
            await this.initialize();
            self.getCompact();

        },

        methods: {
            ...mapActions('caso', [
                'initialize',
                'editItem',
                'borraItem',
                'deleteObject',
                'closeBorraDialog',
                'getCompact',
                'setDialog',
                'setCasosFinalizados',
                'setUsuarioActual',
                'editItemDominioSiniestro'
            ]),

            alertaColor(item) {
                if (item.alerta == 1)
                    return "warning";

                let fin = new Date(item.fAlerta);
                let hoy = new Date();
                return hoy > fin ? "primary" : "blue lighten-5";
            },

            alertaIcono(item) {
                return (item.alerta == 1) ? "mdi-phone-outgoing" : "mdi-calendar-clock";
            },

            buscaDominioSiniestro() {
                this.editItemDominioSiniestro(this.search);
            },

            ChgUsuarioActual(itm) {
                this.setUsuarioActual(itm);
                this.getCompact();
            },

            ChgFinalizados(itm) {
                this.setCasosFinalizados(itm);
            },

            diasrudac(item) {
                if (
                    item.estadoId == this.ESTADOS.ANULADO ||
                    item.estadoId == this.ESTADOS.FINALIZADO ||
                    item.desarmadero == null
                ) {
                    return '';
                }

                if (item.desarmadero.vencimientoRUDAC == null) {
                    return 'color:blue';
                }

                var milliseconds = 24 * 60 * 60 * 1000;
                var rudac = new Date(item.desarmadero.vencimientoRUDAC).getTime();
                var inicio = new Date().getTime();
                var ret = Math.round((rudac - inicio) / milliseconds);

                if (ret > 0 && ret <= 20) {
                    return 'color:red';
                }

                if (ret <= 30) {
                    return 'color:orange';
                }

                return 'color:green';
            },

            dropboxClick(itm) {
                window.open(itm.dropboxLink);
            },

            dsrmdr(item) {
                if (item.desarmadero != null)
                    return item.desarmadero.nombre;
                else
                    return '';
            },

            fechaFrmt(val) {
                if (val === null || val === undefined) return null;
                var str =
                    val.substring(8, 10) +
                    '/' +
                    val.substring(5, 7) +
                    '/' +
                    val.substring(2, 4);
                return str;
                // format date, apply validations, etc. Example below.
                // return this.fromDateVal ? this.formatDate(this.fromDateVal) : "";
            },

            filterText() {
            },

            getEstado(itm) {
                if (itm == null || itm == undefined)
                    return 1;
                return itm.estadoId;
            },

            getGrant(grant) {
                return this.loggedUser.grants.indexOf(grant) >= 0;
            },

            seguimiento(tmt) {
                var tramiteId = tmt;
                if (this.tramiteAll !== undefined) {
                    var r = this.tramiteAll.find(function (itm) {
                        if (tramiteId == itm.id) {
                            return itm.icono;
                        }
                    });
                }
                if (r == null)
                    return null;
                return r.icono;
            },

            selectRow(itm) {
                this.editItem(itm);
            },

            tooltip(item) {
                if (item.alerta == 1)
                    return "Primer contacto";

                let dt = new Date(item.fAlerta);
                return dt.getDate() + '/' + (dt.getMonth() + 1) + '/' + dt.getFullYear();
            },

        },
        components: {
            casoDialogo
        }
    };
</script>
