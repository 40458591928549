var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.visible)?_c('div',[_c('v-data-table',{staticClass:"elevation-2 mt-2",attrs:{"headers":_vm.headers,"dense":"","items":_vm.itemsSelected,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.envia",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{model:{value:(item.envia),callback:function ($$v) {_vm.$set(item, "envia", $$v)},expression:"item.envia"}})]}},{key:"item.link",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{model:{value:(item.link),callback:function ($$v) {_vm.$set(item, "link", $$v)},expression:"item.link"}})]}},{key:"item.icono",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v(" "+_vm._s(_vm.icono(item.tramiteId))+" ")])]}},{key:"item.tramite",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.ftramite(item.tramiteId))+" ")]}},{key:"item.destino",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.neDestino.nombre)+" ")]}},{key:"item.direccionSoporte",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.direccionSoporte(item))+" ")]}},{key:"item.asunto",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.asunto)+" ")]}},{key:"item.mensaje",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.mensaje)+" ")]}}],null,false,1386539451)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }