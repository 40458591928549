ar <template>
    <v-card max-width="300" v-if="guid!=''">
        <v-card-text class="pa-1">
            <v-img :src=imageThumbnailFile
                   aspect-ratio="1"
                   class="bg-grey-lighten-2"
                   cover>
                <template v-slot:placeholder>
                    <v-row align="center" class="fill-height ma-0" justify="center">
                        <v-progress-circular color="grey lighten-5" indeterminate></v-progress-circular>
                    </v-row>
                </template>
            </v-img>
        </v-card-text>
        <v-card-actions class="pa-0">
            <p>{{imageNameTrimed}}</p>
            <v-spacer></v-spacer>
            <a :href="imageFile" :download="imageNameTrimed" target="_blank">
                <v-icon>mdi-cloud-download</v-icon>
            </a>
            <v-btn icon
                   class="ma-2"
                   @click="deleteImage">
                <v-icon>mdi-delete</v-icon>
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import { mapState, mapActions } from 'vuex';
    import { urlImageAPI } from '../../_services/index.js';

    export default {
        name: 'galleryimage',

        props: [
            'guid',
            'imageName'
        ],

        data() {
            return {
            };
        },

        computed: {
            ...mapState('image', ['items', 'item', 'defaultItem', 'index']),
            imageThumbnailFile() {
                return urlImageAPI + 'image/' + this.guid + '/' + this.imageName + '/Thumbnail';
            },

            imageFile() {
                return urlImageAPI + 'image/' + this.guid + '/' + this.imageName;
            },

            imageNameTrimed() {
                return this.imageName;//.replace(/ /g, "_");
            }
        },

        methods: {
            ...mapActions('image', [
                'deleteObject'
            ]),

            deleteImage() {
                this.deleteObject({ domain: this.guid, image: this.imageName });
            },

            downloadImage() {
                this.deleteObject({ domain: this.guid, image: this.imageName });
            }
        }
    };
</script>
