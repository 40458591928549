<!-- Componente de carga dhe imágenes -->
<template>
    <div>
        <button @click="openFileDialog"> <v-icon>mdi-plus</v-icon></button>
        <input type="file" ref="fileInput" @change="onFileChange" style="display: none;" />
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex';

    export default {
        name: 'galleryloadimage',

        props: [
            'domainGuid'
        ],

        data() {
            return {
                base64String: null,
                imageName: null,

            };
        },

        computed: {
            ...mapState('image', ['items', 'item', 'defaultItem', 'index']),
        },

        methods: {
            ...mapActions('image', [
                'postObject',
            ]),

            openFileDialog() {
                // Disparar el evento click en el input file oculto
                this.$refs.fileInput.click();
            },

            onFileChange(event) {
                const file = event.target.files[0];
                this.imageName = file.name;

                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = async () => {
                    this.base64String = reader.result;
                    await this.uploadBase64Image();
                };
            },

            async uploadBase64Image() {
                try {
                    this.postObject({
                        "domain": this.domainGuid, "payload": {
                            "fileName": this.imageName,
                            "imgStream": this.base64String,
                        }
                    });

                    //    // Enviar imagen base64 al backend
                    //    const response = await axios.post('/api/uploadBase64Image', {
                    //        imageName: this.imageName,
                    //        base64String: this.base64String
                    //    });

                    //    // Lógica adicional después de subir la imagen
                } catch (error) {
                    console.error('Error al cargar la imagen:', error);
                }
            },

        //    async uploadImage() {
        //        //const formData = new FormData();

        //        try {
        //            // Recibir URL de la imagen desde el backend y mostrarla
        //            this.imageUrl = response.data.imageUrl;
        //        } catch (error) {
        //            console.error('Error al cargar la imagen:', error);
        //        }
        //    }
        }
    };
</script>
